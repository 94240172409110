import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import { Layout } from "../components/_layout"
import { CategoryParentTabs } from "../components/MapsModule"
import { IMapData } from "../components/MapsModule/MapController/MapController"

const getPageInfo = graphql`
    query {
        mapsData: allContentfulMapasCategoriaPadre(
            sort: { fields: [orden], order: ASC }
        ) {
            edges {
                node {
                    id
                    nombreDeLaCategoria
                    contenido
                    categorias: mapas___categoria {
                        id
                        nombreDeLaCategora
                        orden
                        mapas: mapas___elemento {
                            id
                            nombre
                            nombreCorto
                            urlDelMapa
                        }
                    }
                }
            }
        }
    }
`

export interface IMapCategoryParent {
  categoryParent: string
  categoryParentDescription: string
  categorias?: IMapData[]
}

const Maps: React.FC = () => {
  const { mapsData } = useStaticQuery(getPageInfo)

  const maps: IMapCategoryParent[] = mapsData.edges.map((e: any) => ({
    categoryParent: e.node.nombreDeLaCategoria,
    categoryParentDescription: e.node.contenido,
    ...(e.node.categorias
      ? {
        categorias: e.node.categorias.map((e: any) => ({
          ordenCategoria: e.orden,
          categoria: e.nombreDeLaCategora,
          maps: e.mapas
            ? e.mapas
            .map((e: any) => ({
              id: e.id,
              name: e.nombre,
              shortName: e.nombreCorto,
              mapURL: e.urlDelMapa
            }))
            .sort((a, b) =>
              a.shortName > b.shortName
                ? 1
                : b.shortName > a.shortName
                ? -1
                : 0
            )
            : []
        }))
      }
      : {})
  }))

  return (
    <Layout
      withNav
      seoTitle="Mapas"
      seoDescription="Localidades según su disponibilidad de puntos de infraestructura financiera (sucursal, corresponsal o cajero)."
    >
      <CategoryParentTabs categoriasPadre={ maps } />
    </Layout>
  )
}

export default Maps
